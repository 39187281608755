<template>
  <v-row>
    <v-col cols="12">
      <v-img
        alt="Logo"
        class="organisation-logo"
        contain
        :src="organisationData?.logo_path"
        transition="scale-transition" />
    </v-col>
    <v-col cols="12" sm="6" lg="12">
      <strong>{{ $t('organisationsPage.name') }}:</strong>
      <div>{{ organisationData?.name || '/' }}</div>
    </v-col>
    <v-col cols="12" sm="6" lg="12">
      <strong>{{ $t('organisationsPage.email') }}:</strong>
      <div>{{ organisationData?.email || '/' }}</div>
    </v-col>
    <v-col cols="12" sm="6" lg="12">
      <strong>{{ $t('organisationsPage.phoneNumber') }}:</strong>
      <div>{{ organisationData?.phone_number || '/' }}</div>
    </v-col>
    <v-col cols="12" sm="6" lg="12">
      <strong>{{ $t('organisationsPage.address') }}:</strong>
      <div>{{ organisationData?.address || '/' }}</div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'OrganisationInfo',
  props: ['organisationData'],
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
  watch: {},
};
</script>

<style scoped>
.organisation-logo {
  height: auto;
  width: 100%;
  max-width: 150px;
}
</style>
