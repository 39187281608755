<template>
  <v-card>
    <v-card-title>
      {{ $t('driving.vehicleClass') }}
      <v-spacer />
      <v-col cols="12" sm="3">
        <v-autocomplete
          v-model="defaultCurrency"
          :items="currencies"
          clearable
          item-text="name"
          item-value="id"
          :label="$t('driving.currency')"
          :no-data-text="$t('select.noDataAvailable')"
          @focus="$event.target.click()"
          :disabled="formDisabled"></v-autocomplete>
      </v-col>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" v-for="(item, index) in vehicleClasses" :key="index">
            <v-card
              class="card pa-5"
              :class="{ 'selected-card': selectedVehicleClass === item }"
              @click="$emit('selectVehicleClass', item)">
              <img
                v-if="item.picture_path"
                :src="item.picture_path"
                :alt="item.name"
                class="d-block vehicle-class-img mb-3" />
              <strong class="d-block text-h6 mb-5"> {{ item.name }} </strong>
              <div class="d-flex justify-space-between align-center">
                <div class="d-flex">
                  <div class="d-flex align-center">
                    <v-icon class="mr-1">mdi-account-group</v-icon>
                    <span>
                      {{ item.number_of_passengers }}
                    </span>
                  </div>
                  <div class="d-flex align-center ml-5">
                    <v-icon class="mr-1">mdi-bag-suitcase</v-icon>
                    <span>
                      {{ item.number_of_luggage }}
                    </span>
                  </div>
                </div>
                <span class="d-block text-h6 text-right">
                  <strong>{{
                    item?.price?.price
                      ? item?.price?.price + item?.price?.currency?.symbol + ' (' + item?.price?.currency?.code + ')'
                      : ''
                  }}</strong>
                </span>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary cancel" @click="$emit('prevStep')">{{ $t('buttons.prev') }}</v-btn>
      <v-spacer />
      <button-submit :failed="failed || formDisabled" buttonText="buttons.next"></button-submit>
    </v-card-actions>
  </v-card>
</template>

<script>
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';

export default {
  name: 'VehicleClass',
  props: [
    'editedItem',
    'vehicleClasses',
    'selectedVehicleClass',
    'currencies',
    'organisationDefaultCurrency',
    'failed',
    'formDisabled',
  ],
  components: {
    ButtonSubmit,
  },
  data() {
    return {
      defaultCurrency: null,
    };
  },
  created() {},
  computed: {},
  methods: {},
  watch: {
    organisationDefaultCurrency(val) {
      if (!this.defaultCurrency) {
        this.defaultCurrency = val;
      }
    },
    defaultCurrency(val) {
      this.$emit('handleCurrencyChange', val);
    },
  },
};
</script>

<style scoped>
.card {
  height: 100%;
}

.selected-card {
  outline: 2px solid #1976d2; /* Primary color */
  background-color: #e3f2fd; /* Light shade of primary color */
  box-shadow: unset !important;
}

.selected-card::before {
  display: none;
}

.vehicle-class-img {
  height: auto;
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: center;
}
</style>
