<template>
  <v-card>
    <v-card-title>{{ $t('driving.steps.review') }}</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <!-- Driving Type -->
          <v-col cols="12" sm="6" lg="6">
            <div>
              <strong>{{ $t('driving.drivingType') }}:</strong>
            </div>
            <div>{{ getDrivingTypeLabel(editedItem.driving_type_id) || '/' }}</div>
          </v-col>
          <!-- Pickup Time -->
          <v-col cols="12" sm="6" lg="6">
            <div>
              <strong>{{ $t('driving.pickupTime') }}:</strong>
            </div>
            <div>{{ editedItem.pickup_time || '/' }}</div>
          </v-col>

          <!-- From Location -->
          <v-col cols="12" sm="6" lg="6">
            <div>
              <strong>{{ $t('driving.locationFrom') }}:</strong>
            </div>
            <div>{{ editedItem.from_location_name || '/' }}</div>
          </v-col>
          <!-- To Location (conditionally displayed) -->
          <v-col cols="12" sm="6" lg="6" v-if="editedItem.driving_type_id === 1">
            <div>
              <strong>{{ $t('driving.locationTo') }}:</strong>
            </div>
            <div>{{ editedItem.to_location_name || '/' }}</div>
          </v-col>
          <!-- Waiting Time -->
          <v-col cols="12" sm="6" lg="6" v-if="editedItem.driving_type_id === 2">
            <div>
              <strong>{{ $t('driving.waitingTime') }}:</strong>
            </div>
            <div>{{ editedItem.num_of_waiting_hours || '/' }}</div>
          </v-col>

          <!-- Full Name -->
          <v-col cols="12" sm="6" lg="6">
            <div>
              <strong>{{ $t('driving.fullName') }}:</strong>
            </div>
            <div>{{ editedItem.full_name || '/' }}</div>
          </v-col>
          <!-- Vehicle class -->
          <v-col cols="12" sm="6" lg="6">
            <div>
              <strong>{{ $t('driving.vehicleClass') }}:</strong>
            </div>
            <div>{{ getVehicleClassName(editedItem.vehicle_class_id) || '/' }}</div>
          </v-col>

          <!-- Phone Number -->
          <v-col cols="12" sm="6" lg="6">
            <div>
              <strong>{{ $t('driving.phoneNumber') }}:</strong>
            </div>
            <div>{{ editedItem.phone_number || '/' }}</div>
          </v-col>

          <!-- Email -->
          <v-col cols="12" sm="6" lg="6">
            <div>
              <strong>{{ $t('driving.email') }}:</strong>
            </div>
            <div>{{ editedItem.email || '/' }}</div>
          </v-col>

          <!-- Flight Number -->
          <v-col cols="12" sm="6" lg="6">
            <div>
              <strong>{{ $t('driving.flightNumber') }}:</strong>
            </div>
            <div>{{ editedItem.flight_number || '/' }}</div>
          </v-col>

          <!-- Welcome Sign -->
          <v-col cols="12" sm="6" lg="6">
            <div>
              <strong>{{ $t('driving.waitingBoardText') }}:</strong>
            </div>
            <div>{{ editedItem.waiting_board_text || '/' }}</div>
          </v-col>

          <!-- Suitcase Number -->
          <v-col cols="12" sm="6" lg="6">
            <div>
              <strong>{{ $t('driving.suitcasesNumber') }}:</strong>
            </div>
            <div>{{ editedItem.suitcase_number || '/' }}</div>
          </v-col>

          <!-- Passenger Number -->
          <v-col cols="12" sm="6" lg="6">
            <div>
              <strong>{{ $t('driving.passengersNumber') }}:</strong>
            </div>
            <div>{{ editedItem.passenger_number || '/' }}</div>
          </v-col>

          <!-- Baby Seat Number -->
          <v-col cols="12" sm="6" lg="6">
            <div>
              <strong>{{ $t('driving.babySeatsNumber') }}:</strong>
            </div>
            <div>{{ editedItem.baby_seat_number || '/' }}</div>
          </v-col>

          <!-- Stop Number -->
          <v-col cols="12" sm="6" lg="6">
            <div>
              <strong>{{ $t('driving.stopsNumber') }}:</strong>
            </div>
            <div>{{ editedItem.stop_number || '/' }}</div>
          </v-col>

          <!-- Additional Note -->
          <v-col cols="12" sm="12" lg="12">
            <div>
              <strong>{{ $t('driving.noteDispatcher') }}:</strong>
            </div>
            <div>{{ editedItem.note || '/' }}</div>
          </v-col>

          <v-col cols="12">
            <div v-for="(file, index) in filesToSend" :key="index">
              {{ file.name }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary cancel" @click="$emit('prevStep')">{{ $t('buttons.prev') }}</v-btn>
      <v-spacer />
      <button-submit :loading="loading" :failed="failed || formDisabled" buttonText="buttons.send"></button-submit>
    </v-card-actions>
  </v-card>
</template>

<script>
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';

export default {
  name: 'FormPreview',
  props: ['editedItem', 'drivingTypes', 'vehicleClasses', 'filesToSend', 'loading', 'failed', 'formDisabled'],
  components: { ButtonSubmit },
  data() {
    return {};
  },
  created() {},
  methods: {
    getDrivingTypeLabel(typeId) {
      const drivingType = this.drivingTypes.find((type) => type.id === typeId);
      return drivingType ? drivingType.name : '';
    },
    getVehicleClassName(vehicleClassId) {
      return this.vehicleClasses.find((item) => item.id === vehicleClassId)?.name;
    },
  },
  watch: {},
};
</script>
